<template>
  <div class="home container sbhome">
    <div class="col">
      <div class="box-3">
        <div class="main banner">
          <el-carousel trigger="click" style="height: 310px;" loop>
            <el-carousel-item v-for="banner in banners" :key="banner.id">
              <img :src="banner.http_url" class="banner-item" @click="openURL(banner.link_url)">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <div class="box-3">
        <div class="card lottery lottery_f_box">
          <div class="header">
            <p class="title">
              关注彩种<span></span>
            </p>
            <el-popover placement="bottom" width="160" trigger="click" v-model="is_pop" :visible-arrow="false"
              popper-class="popper-lotteries">
              <div class="lotteries popper-lotteries-check-box">
                <el-checkbox-group v-model="checkedLottery" :max="5">
                  <el-checkbox class="checkbox" v-for="lottery in lotteryOptions" :key="lottery.id"
                    :label="lottery.code" @change="((val) => { lotterySelectChanged(val, lottery.code) })">
                    {{ lottery.name }}</el-checkbox>
                </el-checkbox-group>
                <div class="pop_close" @click="is_pop = false">关闭</div>
              </div>
              <div class="selection" slot="reference">
                <p>更换彩种</p><i class="el-icon-arrow-down"></i>
              </div>
            </el-popover>
          </div>
          <div class="list">
            <div class="item" v-for="lottery in lotteries" :key="lottery.id"
              @click="pushLotteryTableAction(lottery.code, lottery.id)">
              <img class="icon-lottery" :src="lottery.http_logo"
                @click="pushLotteryTableAction(lottery.code, lottery.id)">
              <div class="ticket">
                <p class="name">
                  <b>{{ lottery.name }}</b><span>{{ lottery.expect }}期</span>
                </p>
                <div class="numbers">
                  <number v-for="(number, i) in lottery.numbers" :key="`${i}-${number}`" :value="number"
                    :lottery-code="lottery.code"
                    :lhc_icon="(lottery.code == 'donggan61' || lottery.code == 'amlhc' || lottery.code == 'twlhc' || lottery.code == 'hk6' || lottery.code == 'happy61') && i == 6">
                  </number>
                  <!-- <img src="@assets/pc/icon_lottery_home_number_go.png" class="right"> -->
                </div>
              </div>
              <div class="state">
                <!-- lottery.next_time -->
                <template v-if="lottery.next_time > 0">
                  <p class="title">
                    <img src="@assets/pc/cnm_ui.png" />
                    <span>开奖倒计时</span>
                  </p>
                  <p class="time">
                    <i v-if="parseInt(lottery.next_time / 86400) > 0">
                      <i>{{ lottery.next_time | dayTime | timeFormat }}</i>
                      <span>:</span>
                    </i>
                    <i v-if="parseInt(lottery.next_time / 3600) > 0">
                      <i>{{ lottery.next_time | hourTime | timeFormat }}</i>
                      <span>:</span>
                    </i>
                    <i>
                      <i>{{ lottery.next_time | minTime | timeFormat }}</i>
                      <span>:</span>
                    </i>
                    <i>
                      <i>{{ lottery.next_time | secTime | timeFormat }}</i>
                    </i>
                  </p>
                </template>
                <p class="message" v-else>搅珠状态...</p>
              </div>
              <!-- <div class="live">
                <router-link v-hidden-entrance="{code: lottery.code, id: lottery.id}" :to="`/lottery/live/${lottery.code}/${lottery.id}`" tag="div">
                  <img src="@assets/pc/home_video.png"/>
                  开奖直播
                </router-link>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="box-1">
        <div class="recommend">
          <div class="images">
            <img :src="r.http_url" v-for="r in recommendation" :key="r.id" @click="openURL(r.link_url)">
          </div>
          <div class="card" style="padding-bottom: 0;">
            <div class="header">
              <p class="title">{{ titles.qrcode }}<span></span></p>
            </div>
            <div class="qrcodes">
              <div class="item" v-for="code in qrcode" :key="code.id">
                <img :src="code.http_logo" class="code" />
                <p class="content">
                  {{ code.content_limit }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-1">
        <div class="card links">
          <div class="header">
            <p class="title">最新网址<span></span></p>
          </div>
          <div class="clearfix urls" :style="{ height: url_more[1] ? '327px' : 'auto' }">
            <div class="links-box" v-for="link in lottery_pictures" :key="link.title">
              <div class="links-title">{{ link.title }}</div>
              <div class="links-cont" :class="[link.layout]">
                <el-dropdown v-if="link.hy_children.length > 0" placement="bottom">
                  <span class="el-dropdown-link linke-sele">
                    <span>会员网址<i class="el-icon-caret-bottom"></i></span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item, key) in link.hy_children" :key="key">
                      <a :href="item" target="_blank" class="link_url">
                        {{ link.hy_name_children[key] }}
                        <span>{{ item }}</span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown v-if="link.gl_children.length > 0" placement="bottom">
                  <span class="el-dropdown-link linke-sele">
                    <span>代理网址<i class="el-icon-caret-bottom"></i></span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item, key) in link.gl_children" :key="key">
                      <a :href="item" target="_blank" class="link_url">
                        {{ link.gl_name_children[key] }}
                        <span>{{ item }}</span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown v-if="link.other_children.length > 0" placement="bottom">
                  <span class="el-dropdown-link linke-sele">
                    <span>{{ link.other_name }}<i class="el-icon-caret-bottom"></i></span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item, key) in link.other_children" :key="key">
                      <a :href="item" target="_blank" class="link_url">
                        {{ link.other_name_children[key] }}
                        <span>{{ item }}</span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div v-if="link['layout'] == 'cont-layout1'" style="width: 79px;"></div>
              </div>
            </div>
          </div>
          <div v-show="lottery_pictures.length > 7" class="url_more" @click="url_more[1] = !url_more[1]">更多<i
              class="el-icon-d-arrow-right"></i></div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import vueWaterfallEasy from 'vue-waterfall-easy'
import { Popover, Checkbox, Carousel, CarouselItem, CheckboxGroup, Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import { mapState, mapMutations } from 'vuex'
import Number from '@components/pc/Number'

export default {
  name: 'Home',
  components: {
    [Popover.name]: Popover,
    [Checkbox.name]: Checkbox,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [CheckboxGroup.name]: CheckboxGroup,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Number.name]: Number,
    [vueWaterfallEasy.name]: vueWaterfallEasy
  },
  filters: {
    timeFormat(value) {
      return (Array(2).join(0) + value).slice(-2)
    },
    dayTime(value) {
      return parseInt(value / 86400 % 1000)
    },
    hourTime(value) {
      return parseInt(value / 3600 % 24)
    },
    minTime(value) {
      return parseInt(value / 60 % 60)
    },
    secTime(value) {
      return parseInt(value % 60)
    }
  },
  data() {
    return {
      url_more: [true, true, true, true],
      is_pop: false,
      data: null,
      value: '',
      checked: false,
      timer: null,
      countdownTimer: null,
      checkedLottery: [],
      lotteryOptions: []
    }
  },
  computed: {
    ...mapState('home', [
      'selectedLottery' // -> this.selectedLottery
    ]),
    titles() {
      if (!this.data) return []
      return this.data.titles
    },
    banners() {
      if (!this.data) return []
      return this.data.banner
    },
    lotteries() {
      if (!this.data) return []
      let arr = []
      for (let i in this.selectedLottery) {
        const code = this.selectedLottery[i]
        for (let lottery of this.data.lottery) {
          if (code == lottery.code) {
            lottery.numbers = (lottery.opencode || '').split(',')
            arr.push(lottery)
          }
        }
      }
      return arr
    },
    information() {
      if (!this.data) return []
      return this.data.information
    },
    tips() {
      if (!this.data) return []
      return this.data.tips
    },
    novel() {
      if (!this.data) return []
      return this.data.novel
    },
    pictures() {
      if (!this.data) return []
      return this.data.wonderful_pictures
    },
    links() {
      if (!this.data) return []
      const arr = this.data.links
      let new_arr = []
      for (let i in arr) {
        const obj = arr[i]
        obj['web_children'] = obj['contents'].split(',')
        obj['name_children'] = obj['author'].split(',')
        new_arr.push(obj)
      }
      return new_arr
    },
    football() {
      if (!this.data) return []
      const arr = this.data.football
      let new_arr = []
      for (let i in arr) {
        const obj = arr[i]
        obj['web_children'] = obj['contents'].split(',')
        obj['name_children'] = obj['author'].split(',')
        new_arr.push(obj)
      }
      return new_arr
    },
    lottery_data() {
      if (!this.data) return []
      const arr = this.data.lottery_data
      let new_arr = []
      for (let i in arr) {
        const obj = arr[i]
        obj['web_children'] = obj['contents'].split(',')
        obj['name_children'] = obj['author'].split(',')
        new_arr.push(obj)
      }
      return new_arr
    },
    lottery_pictures() {
      if (!this.data) return []
      const arr = this.data.lottery_pictures
      let new_arr = []
      for (let i in arr) {
        const obj = arr[i]
        obj['web_children'] = obj['contents'].split(',')
        obj['name_children'] = obj['author'].split(',')
        obj['hy_children'] = []
        obj['hy_name_children'] = []
        obj['name_children'].map((item, key) => {
          if (item.indexOf('会员') !== -1) {
            obj['hy_name_children'].push(item)
            obj['hy_children'].push(obj['web_children'][key])
            return item
          }
        })
        obj['gl_name_children'] = []
        obj['gl_children'] = []
        obj['name_children'].map((item, key) => {
          if (item.indexOf('管理') !== -1) {
            obj['gl_name_children'].push(item.replace('管理', '代理'))
            obj['gl_children'].push(obj['web_children'][key])
            return item
          }
        })
        obj['other_children'] = []
        obj['other_name_children'] = []
        obj['other_name'] = ''
        obj['name_children'].map((item, key) => {
          if (item.indexOf('会员') === -1 && item.indexOf('管理') === -1 && obj['title'] != '皇冠（新二）') {
            if (!obj['other_name']) {
              obj['other_name'] = item
            }
            obj['other_name_children'].push(item)
            obj['other_children'].push(obj['web_children'][key])
            return item
          }
        })
        let classN = 0
        if (obj['hy_children'].length > 0) classN += 1
        if (obj['gl_children'].length > 0) classN += 1
        if (obj['other_children'].length > 0) classN += 1
        obj['layout'] = 'cont-layout' + classN
        new_arr.push(obj)
      }
      return new_arr
    },
    qrcode() {
      if (!this.data) return []
      return this.data.qrcode
    },
    recommendation() {
      if (!this.data) return []
      const arr = (this.data.recommendation_picture || []).splice(0, 4)
      return arr
    }

  },
  mounted() {
    this.requestGetHomeData()
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer)
    }
  },
  methods: {
    ...mapMutations('home', [
      'selectLottery', // -> this.selectLottery()
      'unselectLottery' // -> this.unselectLottery()
    ]),
    to_page(path) {
      this.$router.push({
        path: path
      })
    },
    add_0(v) {
      if (parseInt(v) < 10) {
        return '0' + v
      }
      return v
    },
    date_change(t) {
      const date = new Date(parseInt(t) * 1000)
      return this.add_0(date.getMonth() + 1) + '-' + this.add_0(date.getDate())
    },
    getLotteryOptions() {
      if (!this.data) return []
      let arr = []
      if (!this.selectedLottery) {
        this.checkedLottery = ['donggansaiche', 'gdklsf', 'hk6', 'dongganshengxiao', 'dongganwuxing']
        for (let i = 0; i < this.data.lottery.length; i++) {
          const { id, code, name } = this.data.lottery[i]
          const selected = this.checkedLottery.indexOf(code) !== -1
          arr.push({
            id,
            code,
            name,
            selected
          })
        }
        for (let ai in this.checkedLottery) {
          this.selectLottery(this.checkedLottery[ai])
        }
      } else {
        this.checkedLottery = [].concat(this.selectedLottery)
        for (const { id, code, name } of this.data.lottery) {
          const selected = this.selectedLottery.indexOf(code) !== -1
          arr.push({
            id,
            code,
            name,
            selected
          })
        }
      }
      let new_arr = [].concat(arr)
      for (let a in arr) {
        const code = arr[a]['code']
        const idx = this.checkedLottery.indexOf(code)
        if (idx != -1) {
          new_arr = this.swapArray(new_arr, idx, a)
        }
      }
      arr = [].concat(new_arr)
      this.lotteryOptions = arr
    },
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    },
    lotterySelectChanged(select, code) {
      if (select) {
        this.selectLottery(code)
      } else {
        this.unselectLottery(code)
      }
    },
    articleAction(id, obj) {
      if (obj?.index) {
        this.$router.push('/lottery/article/' + obj?.value?.id)
        return
      }
      this.$router.push('/lottery/article/' + id)
    },
    pushLotteryTableAction(code, id) {
      this.$router.push(`/lottery/code/${code}/${id}`)
    },
    openURL(url) {
      window.open(url)
    },
    routerPush(route) {
      this.$router.push(route)
    },
    requestGetHomeData() {
      this.$loading.show()
      this.$api.home().then(res => {
        this.data = res.data
        this.$loading.hide()
        this.startCountDown()
        this.getLotteryOptions()
        this.timer = setInterval(() => {
          this.requestRereshHomeData()
        }, 60000)
      }).catch(err => {
        this.$loading.hide()
        this.$message.error(err)
      })
    },
    requestRereshHomeData() {
      this.$api.home().then(res => {
        this.data = res.data
      }).catch(err => {
        this.$message.error(err)
      })
    },
    startCountDown() {
      if (this.countdownTimer) {
        clearInterval(this.countdownTimer)
      }
      this.countdownTimer = setInterval(() => {
        for (let i = 0; i < this.data.lottery.length; i++) {
          this.data.lottery[i].next_time--
        }
      }, 1000)
    },
    toAnimation() {
      this.$router.push('./feiting')
    },
    toAnimations() {
      this.$router.push('./animation')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
@import "~@scss/pc/mixin";

$box-1-width: 29.36%;

.home {
  background-color: #f1f2f3;

  >.col {
    margin-left: -10px;
    margin-right: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: 1186px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    > {

      .box_full,
      .box-0-5,
      .box-1,
      .box-2,
      .box-1-5,
      .box-3 {
        padding: 10px;
        overflow: hidden;

        // > .main {
        //   background-color: red;
        // }
        >div {
          background-color: #fff;
        }
      }

      .box-0-5 {
        padding: 10px 2px;
        width: 227.8px;

        .title {
          justify-content: center;
        }
      }

      .box-1 {
        padding-right: 0;
        width: $box-1-width;
      }

      .box-1-5 {
        width: (100% - $box-1-width) / 2;
      }

      .box-2 {
        width: 100% - $box-1-width * 2;
      }

      .box-3 {
        width: 100% - $box-1-width;
      }

      .box_full {
        width: 100%;
      }
    }
  }
}

.card {
  border: thin solid $color-border;
  padding-bottom: 20px;

  &.disclosure>.header {

    >.title {
      cursor: pointer;
    }
  }

  >.header {
    padding: 0 18px;
    height: 45px;
    border-bottom: thin solid $color-border;
    box-sizing: border-box;
    position: relative;

    >.title {
      line-height: 45px;
      font-size: 18px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span,
      a {
        font-size: 12px;
        color: #A5A5A5;
        cursor: pointer;
        text-decoration-line: none;
      }
    }

    .selection {
      border: none;
      border-radius: 4px;
      float: right;
      width: auto;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      color: rgba($color: #000000, $alpha: 0.8);
      // background-color: #ddd;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      right: 44px;
      margin-top: -14px;

      p {
        margin-right: 10px;
        width: auto;
        white-space: nowrap;
        text-align: center;
      }

      i {
        // width: 9px;
        // height: 4px;
        // background-image: url("~@assets/pc/icon_lottery_home_down.png");
      }
    }
  }
}

.info,
.banner {
  height: 310px;
  box-sizing: border-box;
}

.article,
.novel,
.picture {
  height: 400px;
}

.info {
  display: flex;
  flex-direction: column;
}

.banner .banner-item {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recommend {
  height: 751px;
  overflow: hidden;

  .qrcodes {
    margin-top: 20px;
    padding: 0 6px;
    padding-bottom: 20px;
    box-sizing: border-box;
    @include clearfix();
    display: flex;

    >.item {
      flex: 1;
      text-align: center;

      >img {
        width: auto;
        height: 110px;
        display: block;
        margin: 0 auto;
        padding: 0;
      }

      >.content {
        margin-top: 12px;
        font-size: 12px;
        line-height: 18px;
        color: rgba($color: #000000, $alpha: 0.5);
        @include ellipsis-with-row(2);
      }
    }
  }

  .images {
    padding-bottom: 20px;
    background-color: #f1f2f3;
  }

  .images>img {
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 114px;
    cursor: pointer;
  }

  .images>img+img {
    margin-top: 16px;
  }
}

.lottery {
  height: 816px;

  >.list {
    padding: 24px;
    height: 771px;
    overflow: hidden;
    box-sizing: border-box;

    >.item {
      height: 125px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      cursor: pointer;

      &+.item {
        margin-top: 24px;
      }

      >.icon-lottery {
        width: 84px;
        height: 84px;
        object-fit: contain;
        align-self: center;
      }

      >.ticket {
        margin-left: 16px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        >.name {
          font-size: 16px;
          line-height: 26px;
          color: #969696;

          b {
            font-size: 26px;
            color: #333;
            font-weight: normal;
          }

          span {
            margin-left: 22px;
            margin-right: 22px;
          }
        }

        >.numbers {
          margin-top: 13px;
          display: flex;

          >.right {
            width: 18px;
            height: 18px;
            align-self: center;
            margin-left: 14px;
            cursor: pointer;
          }

          >.number {
            width: 36px;
            height: 36px;

            &+.number {
              margin-left: 6px;
            }
          }
        }
      }

      >.state {
        flex-shrink: 0;
        width: auto;
        height: auto;

        >.title {
          margin: 0 auto;
          width: 104px;
          display: flex;
          align-items: center;

          >img {
            margin-right: 8px;
            width: 24px;
            height: auto;
          }

          font-size: 14px;
          color: rgba($color: #000000, $alpha: 0.8);
        }

        >.time {
          flex-shrink: 0;
          margin-top: 7px;
          min-width: 124px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #333;
          text-align: center;
          white-space: nowrap;
          font-weight: bold;
          background: url('~@assets/pc/sb_ui_cnm.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;

          i {
            font-style: normal;

            >span {
              margin: 0 1.5px;
              font-size: 18px;
            }
          }
        }

        >.message {
          font-size: 15px;
          color: #fa3e3e;
          font-weight: bold;
        }
      }

      .live {
        margin-left: 42px;
        padding: 0;
        width: 132px;
        display: flex;
        align-items: center;
        justify-content: center;

        >div {
          width: 100%;
          height: 40px;
          font-size: 16px;
          color: #fff;
          border-radius: 4px;
          background-color: #fa3e3e;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        img {
          margin-right: 12px;
          width: 23px;
          height: auto;
        }
      }
    }
  }
}

.links {
  padding-bottom: 0;
  height: auto;
  box-sizing: border-box;
  overflow: hidden;

  .urls {
    padding-top: 4px;
    box-sizing: border-box;
    overflow: hidden;

    .url {
      width: 100%;
      font-size: 14px;
      box-sizing: border-box;
      cursor: pointer;

      >div {
        width: 100%;
      }

      .url_row {
        display: block;
        width: 100%;
        height: 39px;
        color: #222;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #F8F8F8;
          color: #FF6161;
        }

        >span {
          white-space: nowrap;
        }

        >i {
          color: #AAAAAA;
        }
      }

      >span {
        color: #222;
      }

      >a {
        padding-left: 10px;
        padding-right: 10px;
        color: #A5A5A5;
        text-decoration: none;

        &:hover {
          color: #fa3e3e;
        }

        >p {
          float: left;
          display: inline;
        }
      }

      >p {
        float: left;
        display: inline;
      }
    }
  }

  .url_more {
    width: 100%;
    height: 30px;
    color: #FF6161;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    >i {
      margin-left: 8px;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      /* IE 9 */
      -moz-transform: rotate(90deg);
      /* Firefox */
      -webkit-transform: rotate(90deg);
      /* Safari 和 Chrome */
      -o-transform: rotate(90deg);
      /* Opera */
    }
  }
}

.links-box {
  padding: 8px 16px;
  display: flex;
  align-items: center;

  &+.links-box {
    border-top: thin solid #ddd;
  }

  .links-title {
    flex-shrink: 0;
    margin-right: 10px;
    width: 86px;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    background-color: #ddd;
    border-radius: 4px;
  }

  .linke-sele {
    display: flex;
    width: 79px;
    font-size: 12px;
    cursor: pointer;

    >span {
      width: 62px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .links-cont {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .cont-layout1 {
    justify-content: space-around;
  }

  .cont-layout2 {
    justify-content: space-around;
  }

  .cont-layout3 {
    justify-content: space-around;
  }
}

$picture-frames: (
  (x: 0,
    y: 0,
    width: 204px,
    height: 110px,
  ),
  (x: 214px,
    y: 0,
    width: 169px,
    height: 217px,
  ),
  (x: 393px,
    y: 0,
    width: 103px,
    height: 104px,
  ),
  (x: 0,
    y: 120px,
    width: 97px,
    height: 97px,
  ),
  (x: 107px,
    y: 120px,
    width: 97px,
    height: 97px,
  ),
  (x: 393px,
    y: 114px,
    width: 103px,
    height: 104px,
  )
);

$picture-margin: 10px;
$picture-width1: 204px;
$picture-width2: 169px;
$picture-width3: 103px;
$picture-height1: 110px;
$picture-height2: 98px;
$picture-height3: 104px;

.picture .images {
  margin-top: 15px;
  position: relative;

  img {
    position: absolute;
    // background-color: red;
    object-fit: cover;

    @each $frame in $picture-frames {
      $index: index($picture-frames, $frame);

      &:nth-child(#{$index}) {
        left: map-get($frame, x);
        top: map-get($frame, y);
        width: map-get($frame, width);
        height: map-get($frame, height);
      }
    }
  }
}

.articless {
  width: 100%;
  list-style: none;
  padding: 0 17px;
  margin: 0 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  li {
    padding: 14px 0;
    width: 411px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    display: flex;

    &:hover {
      color: #fa3e3e;
    }

    &::before {
      content: '';
      width: 411px;
      height: 1px;
      background-color: #E9E9E9;
      position: absolute;
      left: 0;
      top: 100%;
    }

    &:nth-child(2n) {
      margin-left: 40px;
    }

    >img {
      margin-right: 17px;
      width: 140px;
      height: 90px;
    }

    >div {
      flex: 1;

      >span {
        width: auto;
        height: 56px;
        font-size: 16px;
        line-height: 28px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      >div {
        margin-top: 16px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #A5A5A5;

        >img {
          margin-right: 6px;
          width: 16px;
          height: auto;
        }

        >span {
          margin-right: 16px;
        }
      }
    }
  }
}

.articles_row_box {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  a {
    color: #282828;
    text-decoration-line: none;
  }
}

.articles {
  flex: 1;
  list-style: none;
  padding: 0 18px;
  margin: 0 0;
  box-sizing: border-box;
  padding-bottom: 10px;
  text-decoration-line: none;

  .articles_row {
    flex-shrink: 0;
    height: 35.2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    >span {
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    >b {
      font-weight: normal;
      color: #959595;
    }
  }

  .articles_row:hover {
    color: #fa3e3e;
  }

  li {
    margin-top: 9px;
    font-size: 14px;
    color: rgba($color: #000000, $alpha: 0.8);
    @include ellipsis();

    .text,
    .type {
      cursor: pointer;
    }

    .text:hover {
      color: #0089ff;
      transition: color 0.25s linear;
    }

    .type {
      color: #fa3e3e;

      &:before {
        content: "\005b\0020";
      }

      &:after {
        content: "\0020\005d\0020";
      }
    }
  }
}

.box-0-5-l {
  margin-right: 6px !important;
}
</style>

<style lang="scss">
.popper-lotteries {
  margin-top: 4px !important;
  border-radius: 4px;
  border: thin #d8d8d8 solid;
  box-shadow: none;
  z-index: 89 !important;
  padding: 14px 11px 12px;
}

.el-carousel__container {
  height: 100% !important;
}

.link_url {
  text-decoration: none;
  color: #282828;

  >span {
    margin-left: 20px;
    color: #aaa;

    &:hover {
      color: #FF6161;
    }
  }
}

.pop_close {
  padding-top: 16px;
  color: rgb(250, 62, 62);
  text-align: center;
  cursor: pointer;
}

.articl_h {
  height: 327px !important;
}

.popper-lotteries-check-box .checkbox {
  .el-checkbox__input {
    &.is-checked .el-checkbox__inner {
      background-color: #fa3e3e;
      border-color: #fa3e3e;
    }

    &.is-disabled {
      .el-checkbox__inner {
        background-color: #d8d8d8;
        border-color: #d8d8d8;
      }

      &.is-checked .el-checkbox__inner {
        background-color: #d8d8d8;
        border-color: #d8d8d8;

        &::after {
          border-color: white;
        }
      }

      &+span.el-checkbox__label {
        color: #d8d8d8;
      }
    }

    &.is-focus .el-checkbox__inner {
      border-color: #fa3e3e;
    }
  }

  .el-checkbox__label {
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    color: rgba($color: #000000, $alpha: 0.8);
  }

  .el-checkbox__inner::after {
    border-width: 2px;
  }
}
</style>
